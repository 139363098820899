import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';

@Component({
	standalone: true,
	selector: 'app-table',
	imports: [CommonModule, LoadingSpinnerComponent],
	templateUrl: 'table.component.html',
	styleUrls: ['table.component.scss']
})
export class TableComponent {
	@Input() loading = false;
}
