<p class="small text-tertiary">
	<ng-content></ng-content>
</p>

<button class="icon-only" disabled>
	<i class="fa-solid fa-arrow-left"></i>
</button>

<button class="icon-only" disabled>
	<i class="fa-solid fa-arrow-right"></i>
</button>
