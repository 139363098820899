<ng-container *ngIf="!searching; else searchingRef">
	<div class="icon-container" *ngIf="icon">
		<i [class]="icon"></i>
	</div>
	<h3 *ngIf="header" [title]="header | translate">
		{{ header | translate }}
	</h3>
	<p *ngIf="subheader" [title]="subheader | translate">
		{{ subheader | translate }}
	</p>
	<ng-content></ng-content>
</ng-container>

<ng-template #searchingRef>
	<div class="icon-container">
		<i class="fa-solid fa-magnifying-glass"></i>
	</div>

	<h3 *ngIf="searchHeader" [title]="searchHeader | translate">
		{{ searchHeader | translate }}
	</h3>
	<p *ngIf="searchSubheader" [title]="searchSubheader | translate">
		{{ searchSubheader | translate }}
	</p>

	<ng-container *ngIf="canClear">
		<button
			class="icon margin-top-8"
			[title]="'table.clear' | translate"
			(click)="onClear()"
		>
			<i class="fa-solid fa-xmark"></i>
			{{ 'tables.clear' | translate }}
		</button>
	</ng-container>
</ng-template>
