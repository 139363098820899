import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	Output
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-table-empty-state',
	imports: [CommonModule, TranslateModule],
	templateUrl: 'table-empty-state.component.html',
	styleUrls: ['table-empty-state.component.scss']
})
export class TableEmptyStateComponent {
	@HostBinding('class')
	@Input()
	size: 'small' | 'medium' = 'medium';

	@Input() icon!: string;

	@Input() header!: string;
	@Input() subheader!: string;

	@Input() searching = false;
	@Input() canClear = true;
	@Output() clear = new EventEmitter();

	searchHeader = 'tables.empty.header';
	searchSubheader = 'tables.empty.subheader';

	onClear() {
		this.clear.emit();
	}
}
